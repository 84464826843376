'use client';

import { getJWTValues } from '@commons/js-utils';
import { useEffect } from 'react';
import { setUser } from '../../sentry';

interface Props {
  idToken: string;
}
export default function Sentry({ idToken }: Props) {
  useEffect(() => {
    if (idToken) {
      const user = getJWTValues(idToken);
      setUser({ email: user.email, id: user.id });
    }
  }, [idToken]);
  return <></>;
}
